import { useTranslate } from "src/i18n/useTranslate"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"

export function RemoveMemberDialog({
  deleteMemberData,
  loading,
  onClose,
  onConfirm,
}: {
  deleteMemberData: TDeleteMemberdata | null
  loading: boolean
  onClose: () => void
  onConfirm: (memberId: string, deleteSelf: boolean) => void
}) {
  const { t, langKeys } = useTranslate()
  const removeSelf = deleteMemberData?.deleteSelf

  const title = removeSelf
    ? t(langKeys.organizations_leave_team_confirm_title)
    : t(langKeys.organizations_remove_organization_member_confirmation_title)

  const description = removeSelf
    ? t(langKeys.organizations_leave_team_body)
    : t(langKeys.organizations_team_member_remove_confirmation_body, {
        user: deleteMemberData?.displayName ?? "",
      })
  const confirmLabel = removeSelf
    ? t(langKeys.organizations_leave_team_confirm_button_label)
    : t(langKeys.remove)

  return (
    <ConfirmDialog
      title={title}
      description={description}
      loading={loading}
      open={!!deleteMemberData}
      onClose={onClose}
      confirmButtonProps={{
        color: "destructive",
      }}
      confirmLabel={confirmLabel}
      onConfirm={() => {
        if (!deleteMemberData) {
          return
        }
        onConfirm(deleteMemberData.memberId, !!deleteMemberData.deleteSelf)
      }}
    />
  )
}

interface TDeleteMemberdata {
  memberId: string
  deleteSelf?: boolean
  displayName: string
}
