import { useMemo } from "react"
import styled from "styled-components"

import { HomeGroupRolePicker } from "src/components/Organizations/Organization/HomeGroupRolePicker"
import { HomeRolePicker } from "src/components/Organizations/Organization/HomeRolePicker"
import { OrganizationRolePicker } from "src/components/Organizations/Organization/OrganizationRolePicker"
import { HomeGroupRole } from "src/data/homeGroups/types/homeGroupTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import {
  useDeleteMemberInvite,
  usePatchMemberInvite,
} from "src/data/organizations/queries/inviteQueries"
import {
  HomeRole,
  TOrganizationRole,
} from "src/data/organizations/types/organizationMemberTypes"
import {
  InvitationType,
  TOrganizationInvitation,
} from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { OrganizationParams } from "src/router/organizationRoutes"
import { Routes } from "src/router/routes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { DebugBox } from "src/ui/Debug/DebugBox"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { unique } from "src/utils/genericUtil"

export function MemberInvites({
  orgId,
  invites,
  loading,
  adminAccess,
}: {
  orgId: string
  invites: TOrganizationInvitation[]
  loading?: boolean
  adminAccess?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const homeIds = useMemo(() => getHomeInviteIds(invites), [invites])

  const fetchHomes = useFetchHomes({
    orgId,
    filters: { ids: homeIds ?? [] },
    options: { enabled: !!homeIds.length },
  })

  function header() {
    return [
      <div key={"email"}>{t(langKeys.email)}</div>,
      <div key={"role-title"}>
        {t(langKeys.organizations_organization_role_title)}
      </div>,
      <div key={"misc"}></div>,
    ]
  }

  function rows() {
    return invites.map((invite) => (
      <Row key={invite.id} invite={invite} adminAccess={adminAccess} />
    ))
  }

  if (!invites?.length) {
    return null
  }

  return (
    <div>
      <GridTable
        header={header()}
        rows={rows() ?? []}
        templateColumns="2fr auto auto"
        loading={loading || fetchHomes.isInitialLoading}
        useFallbackResponsiveMode
      />

      <DebugBox data={invites} minimized />
    </div>
  )
}

function Row({
  invite,
  adminAccess,
}: {
  invite: TOrganizationInvitation
  adminAccess?: boolean
}) {
  const inviteDelete = useDeleteMemberInvite()
  const patchInvite = usePatchMemberInvite()
  const orgId = invite.organization_id

  function onDelete(invitationId: string) {
    inviteDelete.mutate({ orgId, invitationId })
  }

  function setRole(role: TOrganizationRole | HomeRole | HomeGroupRole) {
    patchInvite.mutate({ orgId, invitationId: invite.id, role })
  }

  function RolePicker() {
    const disabled =
      patchInvite.isLoading || invite.state === "accepted" || !adminAccess
    switch (invite.type) {
      case InvitationType.ORGANIZATION:
        return (
          <OrganizationRolePicker
            inline
            value={invite.role as TOrganizationRole}
            onChange={(role) => setRole(role)}
            disabled={disabled}
          />
        )

      case InvitationType.HOMEGROUP:
        return (
          <HomeGroupRolePicker
            inline
            value={invite.role as HomeGroupRole}
            onChange={(role) => setRole(role)}
            disabled={disabled}
          />
        )

      case InvitationType.HOME:
        return (
          <HomeRolePicker
            inline
            value={invite.role as HomeRole}
            onChange={(role) => setRole(role)}
            disabled={disabled}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <div>
        <InviteCell {...invite} />
      </div>

      <div>
        <RolePicker />
      </div>

      <div style={{ paddingLeft: spacing.XL3 }}>
        <MoreButton
          onDelete={() => onDelete(invite.id)}
          disabled={!adminAccess}
        />
      </div>
    </>
  )
}

function getHomeInviteIds(invites: TOrganizationInvitation[]) {
  return unique(
    invites
      .filter((invite) => invite.type === InvitationType.HOME)
      .map((invite) => invite.invitation_target_id)
  ).filter(Boolean)
}

function InviteCell({
  email,
  invitation_target_name,
  state,
  code,
  ...inviteRest
}: TOrganizationInvitation) {
  const { t, langKeys } = useTranslate()
  const inviteLink = code
    ? `${window.location.origin}${Routes.Signup.location().pathname}?${
        OrganizationParams.INVITATION_CODE
      }=${code}`
    : null

  return (
    <InviteCellBox>
      <div>{email}</div>

      <div hidden={!invitation_target_name}>
        <InvitationTarget invite={{ invitation_target_name, ...inviteRest }} />
      </div>

      <MText variant="bodyS" color="secondary" hidden={state !== "accepted"}>
        {t(langKeys.organization_invite_accepted)}
      </MText>

      {inviteLink && (
        <CopyText
          value={inviteLink}
          copiedText="Copied!"
          displayText={t(langKeys.organizations_copy_invite_link)}
        />
      )}
    </InviteCellBox>
  )
}

function InvitationTarget({
  invite,
}: {
  invite: Pick<
    TOrganizationInvitation,
    "invitation_target_name" | "invitation_target_id" | "type"
  >
}) {
  const isOrgAdmin = useOrganization().orgAccessLogic.hasAdminAccess

  if (!isOrgAdmin) return <>{invite.invitation_target_name}</>

  switch (invite.type) {
    case InvitationType.ORGANIZATION:
      return (
        <InternalLink to={Routes.Organization.location()}>
          {invite.invitation_target_name}
        </InternalLink>
      )

    case InvitationType.HOMEGROUP:
      return (
        <InternalLink
          to={Routes.HomeGroupDetails.path(invite.invitation_target_id)}
        >
          {invite.invitation_target_name}
        </InternalLink>
      )

    case InvitationType.HOME:
      return (
        <InternalLink to={Routes.Home.location(invite.invitation_target_id)}>
          {invite.invitation_target_name}
        </InternalLink>
      )

    default:
      return <>{invite.invitation_target_name}</>
  }
}

const InviteCellBox = styled.div`
  display: grid;
  gap: ${spacing.XS};
`
